<template>
    <date-range-picker
        :opens="opens"
        v-model="dateRange"
        :auto-apply="true"
        :locale-data="locale"
        :ranges="ranges"
        date-util="moment"
        :show-dropdowns="showDropdowns"
        :single-date-picker="singleDatePicker"
        @update="setDate"
    >
    <template #input="picker">
        <h2 v-if="label">{{label}}</h2>
        <div class="date">
            <div class="picker" v-if="picker.startDate == null">{{placeholder}}</div>
            <div class="picker" v-if="picker.startDate != null">{{selectedValue}}</div>
            <span class="arrow"></span>
        </div>
    </template>

    </date-range-picker>
</template>

<script>

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from 'moment'

export default {
    name: 'DatePicker',
    props: {
        placeholder: String,
        label: String,
        dataField: {
            type: String,
            default: ''
        },
        nestedField: {
            type: String,
            default: ''
        },
        setQuery: Function,
        selectedValue: String,
        opens: String,
        singleDatePicker: [Boolean, String],
        showDropdowns: Boolean
    },
    components: {
        DateRangePicker
    },
    data() {
        return {
            locale: {
                direction: 'ltr',
                format: 'mm-dd-yyyy',
                separator: ' - ',
                applyLabel: 'Välj',
                cancelLabel: 'Avbryt',
                fromLabel: 'Från',
                weekLabel: 'W',
                customRangeLabel: 'Custom Range',
                daysOfWeek: ['Sön','Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör'],
                monthNames: ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'],
                firstDay: 1,
                todayLabel:'Idag'
            },
            dateRange: {
                startDate: null,
                endDate: null
            },
            datePickerChanged: false
        }
    },
    mounted() {
        this.handleSelectedValue(this.selectedValue);
    },
    watch: {
        selectedValue:{
            handler: function(value) {
                this.handleSelectedValue(value)
            },
            deep:true
        }
    },
    computed: {
        ranges() {

            return {
                Idag: [moment().toDate(), moment().toDate()],
                Igår: [
                    moment().subtract(1, "days").toDate(),
                    moment().subtract(1, "days").toDate()
                ],
                "Denna månad": [
                    moment().startOf("month").toDate(),
                    moment().endOf("month").toDate()
                ],
                "Förra månaden": [
                    moment()
                        .subtract(1, "month")
                        .startOf("month").toDate(),
                    moment()
                        .subtract(1, "month")
                        .endOf("month").toDate()
                ],
                "I år": [
                    moment().startOf("year").toDate(),
                    moment().endOf("year").toDate()
                ]
            }
        },
    },
    methods: {
        setDate(value) {
            this.datePickerChanged = true;

            const startDate = moment(value.startDate).format("YYYY-MM-DD");
            const endDate = moment(value.endDate).format("YYYY-MM-DD");

            let query = {};

            if (this.nestedField) {
                query = {
                    nested: {
                        path: [this.nestedField],
                        query: {
                            range: {
                                [this.dataField]: {
                                    gte: startDate,
                                    lte: endDate
                                }
                            }
                        },
                    },
                }
            } else {
                query = {
                    query: {
                        range: {
                            [this.dataField]: {
                                gte: startDate,
                                lte: endDate
                            }
                        }
                    },
                }
            }

            this.$props.setQuery({
               query,
               value: startDate + ' - ' + endDate
            });
        },
        handleSelectedValue(value) {
            if (value == null) {
                this.$props.setQuery({
                    query: null,
                    value: ''
                });
                this.dateRange = {
                    startDate: null,
                    endDate: null
                }
            } else {
                if (!this.datePickerChanged) {
                    value = value.split(' - ');

                    const query = {
                        query: {
                            range: {
                                [this.dataField]: {
                                    gte: value[0],
                                    lte: value[1]
                                }
                            }
                        },
                    }

                    this.$props.setQuery({
                        query,
                        value: value[0] + ' - ' + value[1]
                    });

                    this.dateRange = {
                        startDate: value[0],
                        endDate: value[1]
                    }
                }

                this.datePickerChanged = false;
            }
        }
    },
}

</script>
