<template>

  <div class="widget">

    <div class="widget-header">
      <div class="widget-header-label">
        <h3>{{ title }}</h3>
        <info-box :field="infoBox" />
      </div>
      <div class="widget-header-toolbar">
        <div class="actions">
          <!-- <div class="btn btn-grey btn-bold ml-3"><i class="fas fa-download"></i></div> -->
        </div>
      </div>
    </div>
    <div class="widget-body no-padding">

      <table class="vuetable">
        <thead>
          <tr>
            <th width="35%">{{ title }}</th>
            <th v-for="label in labels" :key="label">{{ label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ title }}</td>
            <td v-for="year in years" :key="year">
                {{ year == 0 ? '' : year.toLocaleString('sv-SE') }}
              </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>

import moment from 'moment';
import InfoBox from '@/components/InfoBox';

export default {
  data() {
    return {
      moment: moment
    }
  },
  props: {
    data: Array,
    title: String,
    yearsSelected: Array,
    infoBox: String,
  },
  components: {
    InfoBox,
  },
  computed: {
    labels() {
      let labels = [];
      this.data.forEach((value) => {
        if (!labels.includes(value.key)) {
          if (this.yearsSelected.length) {
            if (this.yearsSelected.includes(value.key)) {
              labels.push(value.key);
            }
          } else {
            labels.push(value.key);
          }

        }
      });

      return labels;
    },
    years() {

      let labels = [];
      this.data.forEach((value) => {
        if (!labels.includes(value.key)) {
          if (this.yearsSelected.length) {
            if (this.yearsSelected.includes(value.key)) {
              labels.push(value.doc_count);
            }
          } else {
            labels.push(value.doc_count);
          }

        }
      });

      return labels;
    }
  },
  methods: {
    capitalize(word) {
      return word.trim().replace(/^\w/, (c) => c.toUpperCase())
    },
    
    
  }
}
</script>
