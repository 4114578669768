<template>
  <modal name="export-comments-modal" @closed="closePersonTicket" :scrollable="true" :width="660" :height="410"
         :maxHeight="600">
    <h4>Export av händelser</h4>

    <div class="container">
      <div class="row bt-1">
        <div class="col-12 pt-3 mb-3">
          Din export är redo för start. E-post sänds till <strong>{{ this.$store.getters.user.user.email }}</strong> med information om
          hur du kan ladda ner din
          export när den är redo. Exporten finns tillgänglig i 24 timmar, därefter raderas den.
        </div>
      </div>
      <div class="row bt-1 pt-4 pb-4">
        <div class="col-3 ">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">Från</div>
              <input type="date" class="f" @change="dateChange" v-model="dateFrom" id="dateFrom" name="dateFrom" />
            </div>
          </div>
        </div>
        <div class="col-3 mx-4 ">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">Till</div>
              <input type="date" class="" @change="dateChange" v-model="dateTo"  id="dateTo" name="dateTo" />
            </div>
          </div>
        </div>
      </div>
      <div class="row bt-1 pt-4 pb-4">
        <div class="col-4 text-center">
          <h2 class="mb-3 fw-600">{{ exportEstimate.hits }}</h2>
          <h6 class="text-muted text-uppercase small fw-500">Antal händelser</h6>
        </div>
        <div class="col-4 text-center">
          <h2 class="mb-3 fw-600">{{ exportEstimate.estimate_min }}-{{ exportEstimate.estimate_max }} min.</h2>
          <h6 class="text-muted text-uppercase small fw-500">Est. leverans</h6>
        </div>
        <div class="col-4 text-center">
          <h2 class="mb-3 fw-600">{{ exportEstimate.filesize }} mb.</h2>
          <h6 class="text-muted text-uppercase small fw-500">Storlek på export</h6>
        </div>
      </div>
      <div class="row bt-1">
        <div class="col-6 pt-4">
          <button class="btn btn-m btn-light" @click="closePersonTicket"><i class="fas fa-times"></i> annullera</button>
        </div>
        <div class="col-6 pt-4 text-right">
          <button class="btn btn-m btn-success" @click="exportResults"><i class="fas fa-file-export"></i> Börja export
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import BojAPI from '@/api/boj';
import DatePicker from '@/components/DatePicker';
import CustomTicketResults from '@/components/lists/ticket/CustomTicketResults';
import moment from 'moment';

export default {
  props: {
    ticketFilter: String
  },
  components: {
    DatePicker,
    CustomTicketResults,
  },
  data() {
    return {
      query: {

      },
      exportEstimate: {},
      dateFrom: moment().subtract('1', 'day').format('YYYY-MM-DD'),
      dateTo: moment().format('YYYY-MM-DD'),
    };
  },
  mounted() {
    this.$modal.show('export-comments-modal');
    this.dateChange();
  },
  methods: {
    closePersonTicket() {
      this.$emit('closed');
    },
    dateChange() {
      if(this.ticketFilter == 'completed') {
        this.query = {
          sort: [
            {
              created_at: {
                order: "asc"
              }
            }
          ],
          query: {
            bool: {
              must: [{
                range: {
                  created_at: {
                    gte: this.dateFrom,
                    lte: this.dateTo
                  }
                }
              }, {
                nested: {
                  path: 'ticket',
                  query: {
                    match: {
                      "ticket.status": 'Avslutat',
                    },
                  },
                },
              }]
            }
          }
        }
      } else if(this.ticketFilter == 'active') {
        this.query = {
          sort: [
            {
              created_at: {
                order: "asc"
              }
            }
          ],
          query: {
            bool: {
              must: [{
                range: {
                  created_at: {
                    gte: this.dateFrom,
                    lte: this.dateTo
                  }
                }
              }],
              must_not: [{
                nested: {
                  path: 'ticket',
                  query: {
                    match: {
                      "ticket.status": 'Avslutat',
                    },
                  },
                },
              }],
            }
          }
        }
      } else if(this.ticketFilter == 'my-tickets') {
        this.query = {
          sort: [
            {
              created_at: {
                order: "asc"
              }
            }
          ],
          query: {
            bool: {
              must: [{
                range: {
                  created_at: {
                    gte: this.dateFrom,
                    lte: this.dateTo
                  }
                }
              }, {
                nested: {
                  path: 'ticket',
                  query: {
                    match: {
                      "ticket.users.id": this.$store.getters.user.user.id,
                    },
                  },
                },
              }],
            }
          }
        }
      } else if(this.ticketFilter == 'language') {
        this.query = {
          sort: [
            {
              created_at: {
                order: "asc"
              }
            }
          ],
          query: {
            bool: {
              must: [{
                range: {
                  created_at: {
                    gte: this.dateFrom,
                    lte: this.dateTo
                  }
                }
              }],
              must_not: [{
                match: {
                  language: 'Svenska',
                },
              }],
            }
          }
        }
      } else if(this.ticketFilter == 'all') {
        this.query = {
          sort: [
            {
              created_at: {
                order: "asc"
              }
            }
          ],
          query: {
            bool: {
              must: [{
                range: {
                  created_at: {
                    gte: this.dateFrom,
                    lte: this.dateTo
                  }
                }
              }]
            }
          }
        }
      } else if(this.ticketFilter == 'unverified') {
        this.query = {
          sort: [
            {
              created_at: {
                order: "asc"
              }
            }
          ],
          query: {
            bool: {
              must: [{
                range: {
                  created_at: {
                    gte: this.dateFrom,
                    lte: this.dateTo
                  }
                }
              }, {
                nested: {
                  path: 'ticket',
                  query: {
                    match: {
                      "ticket.status": 'Sänt till jour',
                    },
                  },
                },
              }]
            }
          }
        }
      } else if(this.ticketFilter == 'unassigned') {
        this.query = {
          sort: [
            {
              created_at: {
                order: "asc"
              }
            }
          ],
          query: {
            bool: {
              must: [{
                range: {
                  created_at: {
                    gte: this.dateFrom,
                    lte: this.dateTo
                  }
                }
              }],
              must_not: [{
                nested: {
                  path: 'ticket',
                  query: {
                    exists: {
                      field: 'ticket.users.id',
                    },
                  },
                }
              },{
                nested: {
                  path: 'ticket',
                  query: {
                    match: {
                      "ticket.is_anonymized": 1,
                    },
                  },
                },
              }],
            }
          }
        }
      }
      this.estimate();
    },
    estimate() {
      BojAPI.exportCommentsFromElasticEstimate(this.query).then((response) => {
        this.exportEstimate = response.data;
      });
    },
    exportResults() {
      this.exportEstimate = null;
      this.closePersonTicket();
      BojAPI.exportCommentsFromElastic(this.query).then((response) => {
      });
    },
  },
};
</script>
