<template>
    <div class="col-lg-12 col-xl-12 order-lg-2 order-xl-2">
        <div v-for="manual in manuals" :key="manual.id">
          <h5 class="d-inline-block" role="button" @click="getManualById(manual.id)"> {{manual.name}} </h5>
        </div>
    </div>
</template>

<script>
import BojAPI from '@/api/boj';

export default {
    data() {
        return {
            place: '',
            manuals: {}
        }
    },
    mounted() {

        if (this.$store.getters.permissions.includes('access-manual')) {
            this.place = this.currentPlace();

            BojAPI.getManuals(this.place)
            .then((response) => {
                this.setData(response.data)
            })
            .catch((error) => {
            });
        }
    },
    methods: {
        setData(payload) {
            this.manuals = payload;
        },

        currentPlace() {
            let currentPath = this.$router.currentRoute.path.split('/');

            return currentPath[1];
        },


        /**
         * Export results to PDF or Excel.
         *
         * Uses Axios to retrieve the file.
         */
        getManualById(id) {
            this.isDownloading = true;
            BojAPI.getManualName(id)
            .then((response) => {
                this.downloadWithAxios(id, response.data);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    this.$notify({
                        group: 'foo',
                        text: this.$t('labels.no-permission'),
                        type: 'warning',
                        duration: 3000,
                    });
                }
            });
        },

        /**
         *
         * @param url
         * @param format
         */
        downloadWithAxios(id, file, format) {
            BojAPI.getManualFile(id)
            .then((response) => {
                this.fileDownload(response, file.name, file.url);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    this.$notify({
                        group: 'foo',
                        text: this.$t('labels.no-permission'),
                        type: 'warning',
                        duration: 3000,
                    });
                } else {
                    this.$notify({
                        group: 'foo',
                        text: this.$t('labels.unknown-error'),
                        type: 'warning',
                        duration: 3000,
                    });
                }

            });
        },

        /**
         * Force file download using result from Axios.
         */
        fileDownload(response, filename, fileurl) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            const ext = fileurl.split('.').pop();

            link.href = url;

            link.setAttribute('download', filename + '.' + ext);

            document.body.appendChild(link);
            link.click();
            this.isDownloading = false;
        }
    }
}
</script>
