<template>
  <modal name="export-tickets-modal" @closed="closeExportTicket" :scrollable="true" :width="660" :height="310"
         :maxHeight="600">
    <h4>Export av ärende A</h4>

    <div class="container">
      <div class="row bt-1">
        <div class="col-12 pt-3 mb-3">
          Din export är redo för start. E-post sänds till <strong>{{ exportEstimate.email }}</strong> med information om
          hur du kan ladda ner din
          export när den är redo. Exporten finns tillgänglig i 24 timmar, därefter raderas den.
        </div>
      </div>

      <div class="row bt-1 pt-4 pb-4">
        <div class="col-4 text-center">
          <h2 class="mb-3 fw-600">{{ exportEstimate.hits }}</h2>
          <h6 class="text-muted text-uppercase small fw-500">Antal ärenden</h6>
        </div>
        <div class="col-4 text-center">
          <h2 class="mb-3 fw-600">{{ exportEstimate.estimate_min }}-{{ exportEstimate.estimate_max }} min.</h2>
          <h6 class="text-muted text-uppercase small fw-500">Est. leverans</h6>
        </div>
        <div class="col-4 text-center">
          <h2 class="mb-3 fw-600">{{ exportEstimate.filesize }} mb.</h2>
          <h6 class="text-muted text-uppercase small fw-500">Storlek på export</h6>
        </div>
      </div>

      <div class="row bt-1">
        <div class="col-6 pt-4">
          <button class="btn btn-m btn-light" @click="closeExportTicket"><i class="fas fa-times"></i> annullera</button>
        </div>
        <div class="col-6 pt-4 text-right">
          <button class="btn btn-m btn-success" @click="beginTicketExport"><i class="fas fa-file-export"></i> Börja
            export
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import BojAPI from '@/api/boj';

export default {
  props: {
    exportEstimate: Object,
  },
  mounted() {
    this.$modal.show('export-tickets-modal');
  },
  methods: {
    closeExportTicket() {
      this.$emit('closed');
    },

    beginTicketExport() {
      this.$modal.hide('export-tickets-modal');
      this.$emit('beginTicketExport');
    },
  },
};
</script>
