<template>
  <div class="widget notification bg-success">
    <div class="widget-header light">
      <div class="widget-header-label">
        <h3 class="text-white opacity-60"><i class="far fa-calendar-alt mr-2"></i> {{ title }}</h3>
      </div>
    </div>
    <div class="widget-body">
      <div class="carousel slide" data-ride="carousel" data-interval="4000">
        <b-carousel
            id="carousel-1"
            v-model="slide"
            :interval="8000"
            img-width="1024"
            controls=""
            img-height="480"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd">
          <!-- Text slides with image -->
          <div class="carousel-item" v-for="ticket in msg" :key="ticket.id">
            <div class="single-notification" style="min-height:105px">


              <router-link :to="{ name: 'ticket', params: { id: ticket.id }}" class="notification-title text-white">
                {{ $t('labels.call-to') }} {{ ticket.gender_formatted }}, {{ ticket.age_formatted }} {{ $t('labels.year').toLowerCase() }}
              </router-link>
              <div class="notification-body"></div>

              <div class="notification-footer">
                <div class="progress-wrapper">
                  <div class="progress-info ">
                    <div class="progress-label text-white opacity-60"> {{ $t('labels.next-step') }}</div>
                    <div class="progress-value text-white opacity-60">{{ $t('labels.contact-duty') }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-carousel>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Notifications',
  props: {
    msg: Array,
    title: String
  },
  filters: {
    truncate: function (text, length, suffix) {
      return text.substring(0, length) + suffix;
    },
  },
  data() {
    return {
      slide: 0,
      sliding: null
    }
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    }
  }
}
</script>
