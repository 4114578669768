<template>
  <layout-main>
    <save-search v-if="saveSearch" @saveSearch="doSaveSearch" @closed="saveSearch = false"></save-search>
    <send-mail v-if="sendMail" @sendMail="doSendMail" @closed="closeSendMail"></send-mail>
    <div class="home">
      <!-- Header -->
      <div class="subheader">
        <div class="subheader-main">
          <div class="subheader-left">
            <h3>
              {{ $t('labels.duty-tasks') }}
            </h3>
            <span class="separator-line"></span>
            <div class="breadcrumbs">
              <router-link to="/offices" class="breadcrumbs-link">{{ $t('labels.home') }}</router-link>
              <span class="separator-dot"></span>
              <router-link to="/offices" class="breadcrumbs-link">{{ $t('labels.duty-tasks') }}</router-link>
            </div>
          </div>
          <div class="subheader-right">
            <v-guard :permissions="['offices-toolbar-save-search']">
              <button @click="saveSearch = true" class="btn btn-sm btn-light mr-3"><i class="fas fa-save"></i> {{ $t('labels.save-search') }}
              </button>
            </v-guard>
            <v-guard :permissions="['offices-toolbar-email']">
              <button @click="openSendMail" class="btn btn-sm btn-light mr-3"><i class="fas fa-envelope"></i> {{ $t('labels.send-email') }}
              </button>
            </v-guard>
            <v-guard :permissions="['offices-toolbar-export']">
              <b-dropdown v-if="!isDownloading" id="dropdown-1">
                <template v-slot:button-content><i class="fas fa-download"></i> {{ $t('labels.export') }}</template>

                <b-dropdown-group id="dropdown-group-1" header="PDF">
                  <b-dropdown-item-button @click="exportResults('localoffice', 'pdf')">{{ $t('labels.general-information') }}
                  </b-dropdown-item-button>
                  <b-dropdown-item-button @click="exportResults('openinghours', 'pdf')">{{ $t('labels.availability') }}
                  </b-dropdown-item-button>
                </b-dropdown-group>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-group id="dropdown-group-2" header="Excel">
                  <b-dropdown-item-button @click="exportResults('currentinformation', 'excel')">{{ $t('labels.current-information') }}
                  </b-dropdown-item-button>
                  <b-dropdown-item-button @click="exportResults('openinghours', 'excel')">{{ $t('labels.availability') }}
                  </b-dropdown-item-button>
                </b-dropdown-group>
              </b-dropdown>
            </v-guard>
            <button v-if="isDownloading" class="btn btn-success"><i class="fas fa-circle-notch fa-spin"></i> {{ $t('labels.wait') }}
            </button>
          </div>
        </div>
      </div>
      <!-- Header -->

      <div class="row">
        <reactive-base
            app="ticket_local_office"
            :credentials="user.user.es_username + ':' + user.user.es_password"
            :url="elasticUrl"
        >
          <div class="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
            <div class="widget">
              <div class="widget-header">
                <div class="widget-header-label">
                  <h3>{{ $t('labels.search-local-office') }}<small></small></h3>
                </div>
                <div class="widget-header-toolbar">
                  <div class="actions">
                    <div class="filter-action ml-3">
                      <multi-dropdown-list
                          :placeholder="this.$t('labels.police-region')"
                          componentId="policeFilter"
                          :URLParams="true"
                          fuzziness="AUTO"
                          :showSearch="true"
                          sortBy="asc"
                          nestedField="police_region"
                          :react="{ and: ['search', 'postalCode', 'countyFilter', 'municipalityFilter', 'officeFilter', 'DataControllerSensor', 'phoneSearch'] }"
                          :filterLabel="this.$t('labels.police-region')"
                          dataField="police_region.label.raw"
                      ></multi-dropdown-list>
                    </div>
                    <div class="filter-action ml-3">
                      <multi-dropdown-list
                          :placeholder="this.$t('labels.county')"
                          componentId="countyFilter"
                          :URLParams="true"
                          fuzziness="AUTO"
                          :showSearch="true"
                          sortBy="asc"
                          :size="100"
                          nestedField="county"
                          :react="{ and: ['search', 'postalCode', 'policeFilter', 'municipalityFilter', 'officeFilter', 'DataControllerSensor', 'phoneSearch'] }"
                          :filterLabel="this.$t('labels.county')"
                          dataField="county.label.raw"
                      ></multi-dropdown-list>
                    </div>
                    <div class="filter-action ml-3">
                      <multi-dropdown-list
                          :placeholder="this.$t('labels.municipality')"
                          componentId="municipalityFilter"
                          :URLParams="true"
                          fuzziness="AUTO"
                          sortBy="asc"
                          :showSearch="true"
                          :size="1000"
                          nestedField="municipality"
                          :react="{ and: ['search', 'postalCode', 'policeFilter', 'countyFilter', 'officeFilter', 'DataControllerSensor', 'phoneSearch'] }"
                          :filterLabel="this.$t('labels.municipality')"
                          dataField="municipality.label.raw"
                      ></multi-dropdown-list>
                    </div>
                    <div class="filter-action ml-3">
                      <multi-dropdown-list
                          :placeholder="this.$t('labels.victim-support-centre')"
                          componentId="officeFilter"
                          :URLParams="true"
                          sortBy="asc"
                          :size="100"
                          fuzziness="AUTO"
                          :showSearch="true"
                          :react="{ and: ['search', 'postalCode', 'policeFilter', 'countyFilter', 'municipalityFilter', 'DataControllerSensor', 'phoneSearch'] }"
                          :filterLabel="this.$t('labels.victim-support-centre')"
                          dataField="office.raw"
                      ></multi-dropdown-list>
                    </div>
                    <div class="search-action ml-3">
                      <data-search
                          componentId="phoneSearch"
                          :dataField="['phone_1', 'phone_2', 'mobile_1', 'mobile_2']"
                          iconPosition="right"
                          className="data-search"
                          :showClear="true"
                          :URLParams="true"
                          :fuzziness="0"
                          :debounce="100"
                          :placeholder="`${this.$t('labels.phone')} #..`"
                          :filterLabel="`${this.$t('labels.phone')} #`"
                          :autosuggest="false"
                      />
                    </div>
                    <div class="search-action ml-3">
                      <data-search
                          componentId="postalCode"
                          dataField="postal_codes.postal_code.raw"
                          nestedField="postal_codes"
                          iconPosition="right"
                          className="data-search"
                          :showClear="true"
                          :URLParams="true"
                          :fuzziness="0"
                          :debounce="100"
                          :placeholder="`${this.$t('labels.postal-code')}..`"
                          :filterLabel="this.$t('labels.search')"
                          :autosuggest="false"
                      />
                    </div>
                    <div class="search-action ml-3">
                      <data-search
                          componentId="search"
                          :dataField="[
													'label',
													'label_raw',
													'other_places_indexed',
													'post_towns_indexed',
													'exposed_areas_indexed',
													'office',
													'municipality_indexed',
													'police_region_indexed',
													'county_list',
                          'districts_indexed',
													'DataControllerSensor',
												]"
                          iconPosition="right"
                          className="data-search"
                          :showClear="true"
                          :highlight="true"
                          :URLParams="true"
                          :fuzziness="2"
                          :placeholder="`${this.$t('labels.search')}..`"
                          :debounce="100"
                          :filterLabel="this.$t('labels.search')"
                          :autosuggest="false"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="widget-body no-padding">
                <selected-filters class="bb-1 curent-filters pl-4" :clearAllLabel="this.$t('labels.clear')"/>
              </div>
              <div class="widget-body no-padding">
                <ReactiveComponent componentId="DataControllerSensor">
                  <div slot-scope="{ setQuery }">
                    <office-filter :setQuery="setQuery" :query="defaultQuery"/>
                  </div>
                </ReactiveComponent>
                <reactive-list
                    :showResultStats="false"
                    componentId="SearchResult"
                    className="result-list-container"
                    :pagination="true"
                    :dataField="getSortBy"
                    :sortBy="getSortByOrder ? 'desc' : 'asc'"
                    :URLParams="true"
                    :from="0"
                    :size="15"
                    @queryChange="getQueryChange"
                    :react="{ and: ['search', 'postalCode', 'policeFilter', 'countyFilter', 'municipalityFilter', 'officeFilter', 'DataControllerSensor', 'phoneSearch'] }"
                >
                  <template slot="renderNoResults">
                    <div class="empty-placeholder pt-5">
                      <i class="far fa-times-circle"></i>
                      {{ $t('labels.no-results-found') }}
                    </div>
                  </template>
                  <table class="w-100 clickable-vuetable vuetable" slot="render" slot-scope="{ loading, error, data }">
                    <thead v-if="data.length !== 0">
                    <tr>
                      <th
                          width="15%"
                          :class="{ 'sort-up': isSortField('office.raw', true), 'sort-down': isSortField('office.raw', false) }"
                          @click="changeSortBy('office.raw')"
                      >
                        {{ $t('labels.victim-support-centre') }}
                      </th>
                      <th
                          width="15%"
                          :class="{ 'sort-up': isSortField('label.raw', true), 'sort-down': isSortField('label.raw', false) }"
                          @click="changeSortBy('label.raw')"
                      >
                        {{ $t('labels.local-office') }}
                      </th>
                      <th
                          width="25%"
                          :class="{
														'sort-up': isSortField('municipality_list.raw', true),
														'sort-down': isSortField('municipality_list.raw', false),
													}"
                          @click="changeSortBy('municipality_list.raw')"
                      >
                        {{ $t('labels.municipality') }}
                      </th>
                      <th
                          width="43%"
                          :class="{
														'sort-up': isSortField('police_region_list.raw', true),
														'sort-down': isSortField('police_region_list.raw', false),
													}"
                          @click="changeSortBy('police_region_list.raw')"
                      >
                        {{ $t('labels.place') }}
                      </th>
                    </tr>
                    </thead>
                    <tbody v-for="item in data" :key="item.id">
                    <router-link tag="tr" :to="'/offices/localoffice/' + item.id + '/overview'" class="cursor-pointer">
                      <td v-html="item.office"></td>
                      <td v-html="item.label"></td>
                      <td v-html="item.municipality_indexed.replace(/,/g, ', ')"></td>
                      <td style="font-size: 11px;" v-html="item.post_towns_indexed.replace(/,/g, ', ')"></td>
                    </router-link>
                    </tbody>
                  </table>
                </reactive-list>
              </div>
            </div>
          </div>
        </reactive-base>
      </div>
    </div>
    <manual />
  </layout-main>
</template>

<script>
import LayoutMain from '@/views/Layouts/Main';
import Notifications from '@/components/dashboard/Notifications.vue';
import Multiselect from 'vue-multiselect';
import TicketsList from '@/components/lists/ticket/TicketsList.vue';
import SendMail from '@/components/forms/SendMail';
import SaveSearch from '@/components/forms/SaveSearch';
import OfficeFilter from "@/components/offices/OfficeFilter";
import Manual from '@/components/Manual';
import axios from 'axios';
import BojAPI from '@/api/boj';

export default {
  name: 'home',
  components: {
    SendMail,
    Multiselect,
    SaveSearch,
    LayoutMain,
    Notifications,
    OfficeFilter,
    TicketsList,
    Manual
  },
  data() {
    return {
      elasticUrl: process.env.VUE_APP_API_ELASTIC,
      saveSearch: false,
      isDownloading: false,
      sendMail: false,
      latestQuery: {},
      getSortByOrder: true,
      getSortBy: '_score',
      showFilter: false,
    };
  },
  methods: {

    closeSendMail() {
      this.sendMail = false;
    },
    openSendMail() {
      this.sendMail = true;
    },

    doSaveSearch(payload) {
      BojAPI.saveSearch(this.latestQuery, payload.label, 'localoffice', payload.global).then((response) => {
        this.$notify({
          group: 'foo',
          text: this.$t('labels.search-saved'),
          type: 'success',
          duration: 3000,
        });
      });
    },

    doSendMail(payload) {
      BojAPI.sendBulkMailES(this.latestQuery, payload.subject, payload.body, payload.signature, 'localoffice').then((response) => {
        this.$notify({
          group: 'foo',
          text: this.$t('labels.mail-sent'),
          type: 'success',
          duration: 3000,
        });
      });
    },

    /**
     *
     */
    exportResults(template, format) {
      console.log(template);
      console.log(format);
      this.isDownloading = true;
      BojAPI.exportCurrentSearch('localoffices', this.latestQuery, template, format).then((response) => {
        this.forceFileDownload(response, format, template);
      });
    },

    /**
     *
     */
    forceFileDownload(response, format, template) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      let filename = '';
      if (template == 'localoffice') {
          filename = 'Allmän information';
      }
      if (template == 'openinghours') {
          filename = 'Tillgänglighet';
      }
      if (template == 'currentinformation') {
          filename = 'Aktuella uppgifter';
      }
      filename += '.' + (format == 'excel' ? 'xls' : 'pdf');

      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      this.isDownloading = false;
    },

    /**
     *
     * @param url
     * @param format
     */
    downloadWithAxios(url, format) {
      axios({
        method: 'get',
        url: url,
        responseType: 'arraybuffer',
      })
          .then((response) => {
            this.forceFileDownload(response, format);
          })
          .catch(() => console.log('error occured'));
    },

    getQueryChange(prevQuery, nextQuery) {
      this.latestQuery = nextQuery;
    },
    pageChange() {
    },

    getBucketCount(searchState, componentId, aggregationId, key) {
      if (Object.prototype.hasOwnProperty.call(searchState[componentId], 'aggregations')) {
        let aggregation = searchState[componentId].aggregations[aggregationId];
        if (Object.prototype.hasOwnProperty.call(aggregation, 'buckets')) {
          let buckets = searchState[componentId].aggregations[aggregationId].buckets.find((item) => item.key === key);
          if (buckets) {
            return buckets.doc_count.toLocaleString('sv-SE');
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },

    isSortField(field, direction) {
      if (this.getSortBy === field && this.getSortByOrder === direction) {
        return true;
      } else {
        return false;
      }
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    changeSortBy(value) {
      this.getSortBy = value;
      this.getSortByOrder = !this.getSortByOrder;
      this.updateQueryStringParam('sort', value);
      this.updateQueryStringParam('sortOrder', this.getSortByOrder);
    },

    updateQueryStringParam(key, value) {
      var baseUrl = [location.protocol, '//', location.host, location.pathname].join(''),
          urlQueryString = document.location.search,
          newParam = key + '=' + value,
          params = '?' + newParam;

      // If the "search" string exists, then build params from it
      if (urlQueryString) {
        var keyRegex = new RegExp('([?&])' + key + '[^&]*');

        // If param exists already, update it
        if (urlQueryString.match(keyRegex) !== null) {
          params = urlQueryString.replace(keyRegex, '$1' + newParam);
        } else {
          // Otherwise, add it to end of query string
          params = urlQueryString + '&' + newParam;
        }
      }
      history.pushState({}, '', baseUrl + params);
    },
  },

  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sort = urlParams.get('sort');
    const sortOrder = urlParams.get('sortOrder');

    if (sort) {
      this.getSortBy = sort;
    }
    if (sortOrder) {
      this.getSortByOrder = sortOrder == 'true';
    }
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
    defaultQuery() {
      if (this.$store.getters.permissions.includes('offices-access-office')) {
        return {
          query: {
            bool: {
              must: [
                {
                  match: {
                    'office.raw': this.user.user.member.local_office.office.label,
                  },
                },
              ],
            },
          },
        };
      } else if (this.$store.getters.permissions.includes('offices-access-only-local-office')) {
        return {
          query: {
            bool: {
              must: [
                {
                  match: {
                    'label.raw': this.user.user.member.local_office.label,
                  },
                },
              ],
            },
          },
        };
      } else {
        return {
          query: {
            bool: {
              'must_not': [
                {
                  match: {
                    'office.raw': 'Placeholder',
                  },
                },
              ],
            },
          },
        };
      }
    },
  },
};
</script>
