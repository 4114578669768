<template>
  <modal name="send-message-modal" @closed="closeSendMail" :scrollable="true" :width="800" :height="200"
         :maxHeight="600">
    <div class="clearfix">
      <h4 class="float-left">{{ $t('labels.save-search') }}</h4>
      <button type="submit" class="btn btn-success float-right mt-3 mr-3" @click="saveSearch">{{ $t('labels.save') }}</button>
      <button type="submit" class="btn btn-outline-danger float-right mt-3 mr-3" @click="closeSendMail">{{ $t('labels.cancel') }}</button>
    </div>
    <div class="container bt-1 pt-3">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="subject">{{ $t('labels.name') }}</label>
            <input type="text" v-model="label" class="form-control" id="subject" aria-describedby="emailHelp"
                   placeholder=""/>
          </div>
          <div class="form-check">
            <input class="form-check-input" v-model="global" type="checkbox" value="" id="defaultCheck1"/>
            <label class="form-check-label" for="defaultCheck1">
              {{ $t('labels.make-available-everyone') }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import BojAPI from '@/api/boj';

export default {
  data() {
    return {
      label: '',
      global: false,
      getSortByOrder: true,
      getSortBy: '_score',
    };
  },
  mounted() {
    this.$modal.show('send-message-modal');
  },
  methods: {
    closeSendMail() {
      this.$emit('closed');
    },
    saveSearch() {
      if (this.label.length === 0) {
        alert(this.$t('labels.need-to-enter-name'));
        return false;
      }
      this.$modal.hide('send-message-modal');
      this.$emit('closed');
      this.$emit('saveSearch', {label: this.label, global: this.global});
    },
  },
};
</script>
