<template>

    <div class="widget">

      <div class="widget-header">
        <div class="widget-header-label">
          <h3>{{ title }}</h3>
          <info-box :field="infoBox" />
        </div>
      </div>
      <div class="widget-body no-padding">

        <table class="vuetable">
          <thead>
            <tr>
              <th width="35%">{{ title }}</th>
              <th v-for="label in labels" :key="label.key">{{ label }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="agg in data" :key="agg.key">
              <td v-if="title == 'Månad'">{{ capitalize(moment().locale('sv').month(agg.key).subtract(1, 'M').format('MMMM')) }}</td>
              <td v-else>{{ agg.key }}</td>

              <td v-for="year in years(agg)" :key="year.key">
                {{ year.doc_count == 0 ? '' : year.doc_count.toLocaleString('sv-SE') }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>


  </template>
  <script>

  import moment from 'moment';
  import InfoBox from '@/components/InfoBox';

  export default {
    data() {
      return {
        moment: moment
      }
    },
    props: {
      data: Array,
      title: String,
      yearsSelected: Array,
      yearsAggs: Array,
      infoBox: String,
    },
    components: {
      InfoBox,
    },
    computed: {
      labels() {

        let labels = [];
        this.data.forEach((value) => {

          if(Object.prototype.hasOwnProperty.call(value, 'reverted')) {
            this.yearsAggs.forEach((bucket) => {
              if (!labels.includes(bucket.key)) {

                if (this.yearsSelected.length) {
                  if (this.yearsSelected.includes(bucket.key)) {
                    labels.push(bucket.key);
                  }
                } else {
                  labels.push(bucket.key);
                }

              }

            })
          } else {
            this.yearsAggs.forEach((bucket) => {
              if (!labels.includes(bucket.key)) {

                if (this.yearsSelected.length) {
                  if (this.yearsSelected.includes(bucket.key)) {
                    labels.push(bucket.key);
                  }
                } else {
                  labels.push(bucket.key);
                }

              }

            })
          }
        });

        return labels;
      }
    },
    methods: {
      capitalize(word) {
        return word.trim().replace(/^\w/, (c) => c.toUpperCase())
      },
      years(agg) {
        if(Object.prototype.hasOwnProperty.call(agg, 'reverted')) {
          if (this.yearsSelected.length) {

            const newYearsArray = this.yearsSelected.map((selectedYear) => {
              const yearExists = agg.reverted.year.buckets.find(y => {
                  return y.key == selectedYear
              })

              if (yearExists) {
                return yearExists
              } else {
                return {
                  'doc_count': 0,
                  'key': selectedYear
                }
              }
            })

            return newYearsArray;
          }

          const yearArray = this.yearsAggs.map(a => {

            const buckets =  agg.reverted.year.buckets.find(y => {

              return y.key == a.key
            })

            return buckets == undefined ? 0 : buckets;
          })

          return yearArray
        } else {
          if (this.yearsSelected.length) {

            const newYearsArray = this.yearsSelected.map((selectedYear) => {
              const yearExists = agg.year.buckets.find(y => {
                  return y.key == selectedYear
              })

              if (yearExists) {
                return yearExists
              } else {
                return {
                  'doc_count': 0,
                  'key': selectedYear
                }
              }
            })

            return newYearsArray;
          }

          const yearArray = this.yearsAggs.map(a => {

            const buckets =  agg.year.buckets.find(y => {

              return y.key == a.key
            })

            return buckets == undefined ? 0 : buckets;
          })

          return yearArray;
        }
      }
    }
  }
  </script>
