<script>
export default {
  name: "custom-results",
  props: {
    setQuery: Function,
    query: Object
  },
  mounted() {
    this.setQuery(
        this.query
    );
  },
  render: h => null
};
</script>
